"use client";

import VerticalPhoneNumber from "@/app/components/verticalPhoneNumbers";
import BetterBusinessBureauBadge from "@/app/layouts/standard/footer/betterBusinessBureauBadge";
import EmailSubscribe from "@/app/layouts/standard/footer/emailSubscribe";
import GoogleReviewBadge from "@/app/layouts/standard/footer/googleReviewBadge";
import SocialMediaLinks from "@/app/layouts/standard/footer/socialMediaLinks";
import TakeDownRequests from "@/app/layouts/standard/footer/takeDownRequests";
import TrustPilotBadge from "@/app/layouts/standard/footer/trustPilotBadge";
import { useGetUserQuery } from "@/store/userApi";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo } from "react";
import style from "./standardPageFooter.oldStyle.module.scss";
const currentYear = new Date().getFullYear().toString();

export default function StandardPageFooter() {
  const { data: user } = useGetUserQuery();
  const pathname = usePathname();
  const createAccountURL = useMemo(() => {
    return `/create-account${pathname ? `?origin=${encodeURIComponent(pathname)}` : ""}`;
  }, [pathname]);

  return (
    <footer id={style["footer"]}>
      {user && user.IsGuestUser === true && (
        <div id="footer-ready-to-get-started-row">
          <div id="footer-ready-to-get-started-banner">
            <div className="get-started-text">
              <span className="ready-header-text">
                <h2>Ready to get started?</h2>
              </span>
              <span className="ready-description-text">Sign up (it&apos;s free) in under 30 seconds.</span>
            </div>
            <div className="get-started-cta">
              <a
                href={createAccountURL ? createAccountURL : "/create-account"}
                className="btn btn-lg btn-secondary btn-pillbox"
              >
                Get started {String.fromCharCode(187)}
              </a>
            </div>
          </div>
        </div>
      )}
      <div id="footer-other-rows">
        <EmailSubscribe />
        <aside id="customer-service-callout" className="light lg">
          Need help? Call{" "}
          <VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" className={style["phoneNumber"]} />{" "}
          Extended hours {process.env.NEXT_PUBLIC_COMPANY_OPEN_DAYS_SINGLE}{" "}
          {process.env.NEXT_PUBLIC_COMPANY_OPEN_DAYS_HOURS}{" "}
        </aside>
        <ul id="vertical-links">
          <li>
            <strong>Net32</strong>
          </li>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <Link href={"/ec/dental-supplies"}>Dental Supplies</Link>
          </li>
          <li>
            <Link className="weekly-specials-link" href="/weekly-specials">
              Weekly Specials
            </Link>
          </li>
          <li>
            <Link className="new-arrivals" href="/new-arrivals">
              New Arrivals
            </Link>
          </li>
          <li>
            <Link href="/mfr-promotions">Promotions</Link>
          </li>
          <li>
            <a href="/vendor/become-a-vendor">Become a Vendor</a>
          </li>
          <li>
            <a href="/vendor">Vendor List</a>
          </li>
          <li>
            <a href="/site-map">Site Map</a>
          </li>
        </ul>
        <ul id="corporate-info">
          <li>
            <strong>Corporate Info</strong>
          </li>
          <li>
            <Link href="/about-us">About Us</Link>
          </li>

          <li>
            <a href="/why-shop-net32">Why Shop Net32?</a>
          </li>

          <li>
            <a href="/about-us/reviews">Customer Reviews</a>
          </li>
          <li>
            <a href="/careers">Careers</a>
          </li>
          <li>
            <a href="https://www.net32.com/modern-practice/" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <a href="/terms-of-service">Terms of Service</a>
          </li>
          <li>
            <a href="/privacy-security">Privacy Policy</a>
          </li>
          <li>
            <a href={"/user-privacy-settings"}>Privacy Preferences</a>
          </li>
          <li>
            <a href="/privacy-security#12" target="_blank" rel="noreferrer">
              Do Not Sell or Share My Personal Information
            </a>
          </li>
        </ul>
        <ul id="my-account-footer-links">
          <li>
            <strong>My Account</strong>
          </li>
          <li>
            <Link className="my-account-link" href="/account">
              My Account
            </Link>
          </li>
          <li>
            <a href="/shopping-cart">Shopping Cart</a>
          </li>
          <li>
            <a className="checkout-tracking-link" href="/checkout?isGuest=false">
              Checkout
            </a>
          </li>
          <li>
            <a href="/shopping-list">Shopping List</a>
          </li>
          <li>
            <a href="/account/orders">My Orders</a>
          </li>
          <li>
            <a href="/account/orders/auto-order">Auto Order</a>
          </li>
          <li>
            <a href="/reorder" className="easy-reorder-link">
              Reorder
            </a>
          </li>
        </ul>
        <ul id="let-us-help-links">
          <li>
            <strong>Let Us Help You</strong>
          </li>
          <li>
            <a href="/contact-us">Contact Us</a>
          </li>
          <li>
            <a href="/why-shop-net32#top-faqs-section">FAQ&apos;s</a>
          </li>
          <li>
            <a href="/account/orders/tracking">Order Tracking</a>
          </li>
          <li>
            <a href="/guarantee">30 Day Money Back Guarantee</a>
          </li>
          <li>
            <a href="/giveaways/purchase">Purchase Giveaway Rules</a>
          </li>
          <li>
            <a href="/giveaways/ratings-and-reviews">Review Giveaway Rules</a>
          </li>
          <TakeDownRequests />
        </ul>
        <ul id="mobile-footer-links">
          <li className="mobile-customer-service-callout">
            <div className="questions-please-contact">
              Questions? Please call {process.env.NEXT_PUBLIC_COMPANY_OPEN_DAYS_SINGLE},{" "}
              {process.env.NEXT_PUBLIC_COMPANY_OPEN_DAYS_HOURS}
            </div>
            <div className="mobile-contact-bar">
              <VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" className={style["phoneNumber"]} />
              &nbsp;&middot;&nbsp;
              <a href={"mailto:support@net32.com"}>support@net32.com</a>
            </div>
          </li>
          <li className="mobile-my-account-footer-link">
            <a className="my-account-link" href="/account">
              My Account
            </a>
          </li>
          <li className="mobile-order-history-footer-link">
            <a href="/account/orders">My Orders</a>
          </li>
          <li className="mobile-auto-order-footer-link">
            <a href="/account/orders/auto-order">Auto Order</a>
          </li>
          <li className="mobile-reorder-footer-link">
            <a href="/reorder">Reorder</a>
          </li>
          <li className="mobile-vendor-footer-link">
            <a href="/vendor">Vendors</a>
          </li>
          <li className="mobile-contact-us-footer-link">
            <a href="/contact-us">Contact Us</a>
          </li>
          <li className="mobile-top-sellers-footer-link">
            <Link href="/top-sellers">Top Sellers</Link>
          </li>
          <li className="mobile-terms-footer-link">
            <a href="/terms-of-service">Terms of Service</a>
          </li>
          <li className="mobile-privacy-footer-link">
            <a href="/privacy-security">Privacy Policy</a>
          </li>
          <li className="mobile-privacy-settings-link">
            <a href="/user-privacy-settings">Privacy Preferences</a>
          </li>
          <li className="mobile-dont-sell-info-footer-link">
            <a href="/privacy-security#12" target="_blank" rel="noreferrer">
              Do Not Sell or Share My Personal Information
            </a>
          </li>
        </ul>
        <section id="trust-badges" className="d-flex justify-content-center align-items-center flex-wrap">
          <TrustPilotBadge />

          <GoogleReviewBadge />

          <BetterBusinessBureauBadge />

          <div className="hq-in-nc-badge">
            <Image
              alt="Headquartered in North Carolina, USA"
              src="/media/dental/common/icons/hq-in-nc-icon2x.png"
              width={294}
              height={41}
            />
          </div>
        </section>
        <section id="copyright-info">
          <p>
            Copyright © {currentYear} Net32 Inc. All rights reserved. Net32&apos;s proprietary comparison shopping
            software is protected by U.S. Patent #7107226.
          </p>
          <p>Net32 is the leading dental supply online marketplace.</p>
          <p>
            Pricing shown for Net32 is the current best pricing offered by vendors at{" "}
            <a href={"https://www.net32.com/"}>www.net32.com</a> and updated regularly. Pricing subject to change.
          </p>
          <p>
            Neither Net32 nor SSWhite are affiliated with Dentsply Sirona. Price savings comparisons were provided by
            SSWhite and are based on published prices; actual prices may vary. Net32 assumes no responsibility for the
            accuracy of claims or statements made by third parties.
          </p>
        </section>
        <aside id="social-media-links">
          <SocialMediaLinks />
        </aside>
      </div>
    </footer>
  );
}
