// The keys in this file are sourced from keys.yaml
const apiKeys = {
  stripe: process.env.NEXT_PUBLIC_STRIPE,
  googleRecaptcha: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V1,
  googleRecatpchaV3: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3,
  googleTagManager: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
  googleTagManagerAuth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  googleTagManagerEnv: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT,
  searchSpring: process.env.NEXT_PUBLIC_SEARCH_SPRING,
  /** Will be equal to the string "null" if not available due to environment */
  klaviyo: process.env.NEXT_PUBLIC_KLAVIYO,
  topsort: process.env.NEXT_PUBLIC_TOPSORT_JS_API_KEY,
};

export default apiKeys;
