import { FormControl, FormControlProps } from "react-bootstrap";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";

const EmailInput: BsPrefixRefForwardingComponent<"input", FormControlProps> = ({ ...rest }) => {
  return (
    <FormControl
      suppressHydrationWarning // Password managers like to latch onto this field before the app has hydrated
      type="email"
      pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
      title="Please enter a valid Email Address (i.e., name@domain.com)."
      autoComplete="email"
      {...rest}
    />
  );
};

export default EmailInput;
