"use client";

import { useQuery } from "@/app/hooks/useQuery";
import AlgoliaSearchField from "@/app/layouts/standard/header/algoliaSearchField";
import DeliverToShippingAddress from "@/app/layouts/standard/header/deliverToShippingAddress";
import MyAccountMenu from "@/app/layouts/standard/header/myAccountMenu";
import ShoppingCartSummary from "@/app/layouts/standard/header/shoppingCartSummary";
import UserSalutations from "@/app/layouts/standard/header/userSalutations";
import SubCategoryMainMenuContainer from "@/components/sub-category-menu/subCategoryMainMenuContainer";
import SubCategoryMainMenuContainerMobile from "@/components/sub-category-menu/subCategoryMainMenuContainerMobile";
import { upsertQueryData, useLazyGetTopCategoriesQuery } from "@/store/baseApi";
import { useAppDispatch } from "@/store/hooks";
import { useGetUserQuery } from "@/store/userApi";
import Cookies from "js-cookie";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Overlay, Popover } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Swiper as SwiperInterface } from "swiper";
import { FreeMode, Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AlgoliaSearchBarClient from "./algoliaSearchBarClient";
import style from "./standardPageHeader.oldStyle.module.scss";

type StandardPageHeaderProps = {
  topCategoriesFromServer?: WindfallRestfulResponse.ResponsePieces.TopCategory[];
  /**
   * Set this to `true` if the parent component passes `<InstantSearch />`
   * This is only relevant while we are running both a
   */
  instantSearchProvided?: boolean;
  headerSearchEnabled?: boolean;
};

export default function StandardPageHeader({
  topCategoriesFromServer = [],
  instantSearchProvided,
  headerSearchEnabled = true,
}: StandardPageHeaderProps) {
  const { data: user, isLoading: userIsLoading } = useGetUserQuery();

  const dispatch = useAppDispatch();

  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showMyAccountPreview, setShowMyAccountPreview] = useState(false);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { loginURL, createAccountURL } = useMemo(() => {
    if (pathname) {
      const currentPathForRedirect = encodeURIComponent(pathname);
      const loginURL = "/login?origin=" + currentPathForRedirect;
      const createAccountURL = "/create-account?origin=" + currentPathForRedirect;
      return {
        currentPathForRedirect: currentPathForRedirect,
        loginURL: loginURL,
        createAccountURL: createAccountURL,
      };
    } else {
      return {
        currentPathForRedirect: "",
        loginURL: "/login",
        createAccountURL: "/create-account",
      };
    }
  }, [pathname]);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const shoudShowSwiperSideNavigationArrows = (): boolean => {
    if (swiperInstance) {
      return swiperInstance.allowSlidePrev === true || swiperInstance.allowSlideNext === true;
    }
    return false;
  };

  // const [performSearch, { data: searchResults, isFetching, isError }, lastArgsUsed] = useLazyPerformSearchQuery();
  const [
    getTopCategories,
    { data: topCategories, isFetching: topCategoriesIsFeteching, isError: topCategoriesIsError },
  ] = useLazyGetTopCategoriesQuery();

  useEffect(() => {
    if (!topCategories && topCategoriesIsError === false) {
      if (topCategoriesFromServer.length > 0) {
        dispatch(upsertQueryData("getTopCategories", undefined, topCategoriesFromServer));
        // The following is required to fill topCategories with data, the true tells it
        // to use the cache of what we just inserted.  This is required since this is a lazy query.
        getTopCategories(undefined, true);
      } else if (topCategoriesIsFeteching === false) {
        getTopCategories(undefined, true);
      }
    }
  }, [
    dispatch,
    getTopCategories,
    topCategories,
    topCategoriesFromServer,
    topCategoriesIsError,
    topCategoriesIsFeteching,
  ]);

  // desktop and mobile have different state variables because of the My Account Preview.
  // It was initially showing on mobile even though it shouldn't, so this was the easiest remedy.
  const [showMobileMyAccount, setShowMobileMyAccount] = useState(false);

  const [myAccountPreviewShown, setMyAccountPreviewShown] = useState(false);
  const myAccountRef = useRef(null);

  const [showTopCategories, setShowTopCategories] = useState(false);
  const topCategoriesRef = useRef(null);

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [focusSearch, setFocusSearch] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [forceHideAutoComplete, setForceHideAutoComplete] = useState<boolean>(false);

  const myAccountPreviewTimeout = useRef<NodeJS.Timeout | null>(null);
  const showMyAccountTimeout = useRef<NodeJS.Timeout | null>(null);
  const topCategoriesTimeout = useRef<NodeJS.Timeout | null>(null);

  const query = useQuery();

  useEffect(() => {
    if (user?.UserId && window.aa) {
      try {
        window.aa("setUserToken", `${user.UserId}`);
      } catch (error) {
        console.error(`window.aa("setUserToken") has failed:`, error);
      }
    }
  }, [user]);

  useEffect(() => {
    const hasSeenMyAccountPreview = Cookies.get("n32HasSeenSigninPopup") === "true" ? true : false;

    if (
      hasSeenMyAccountPreview === false &&
      showMyAccount === false &&
      myAccountPreviewShown === false &&
      userIsLoading === false &&
      user?.IsGuestUser === true
    ) {
      Cookies.set("n32HasSeenSigninPopup", "true", { expires: 1 });
      if (myAccountPreviewTimeout.current !== null) {
        myAccountPreviewTimeout.current = setTimeout(() => {
          setShowMyAccountPreview(true);
          setMyAccountPreviewShown(true);
          setShowMyAccount(true);
        }, 1000);
      }
    }
    return () => {
      if (myAccountPreviewTimeout.current !== null) {
        clearTimeout(myAccountPreviewTimeout.current);
      }
    };
  }, [myAccountPreviewShown, showMyAccount, user?.IsGuestUser, userIsLoading]);

  useEffect(() => {
    if (focusSearch == true) {
      (searchInputRef.current as HTMLInputElement).focus();
      setFocusSearch(false);
    }
  }, [focusSearch]);

  const showMyAccountMenu = useCallback(() => {
    if (showMyAccountTimeout.current !== null) clearTimeout(showMyAccountTimeout.current);
    showMyAccountTimeout.current = setTimeout(() => {
      setShowMyAccount(true);
      setShowMobileMyAccount(true);
      setShowMyAccountPreview(false);
      setShowHamburgerMenu(false);
      if (myAccountPreviewTimeout.current !== null) clearTimeout(myAccountPreviewTimeout.current);
    }, 50);
  }, []);

  const closeMyAccountMenu = useCallback(() => {
    if (showMyAccountTimeout.current !== null) clearTimeout(showMyAccountTimeout.current);
    showMyAccountTimeout.current = setTimeout(() => {
      setShowMyAccount(false);
      setShowMobileMyAccount(false);
      setShowMyAccountPreview(false);
    }, 100);
  }, []);

  const showSearchBarOnMobile = useCallback(() => {
    closeMyAccountMenu();
    setFocusSearch(true);
  }, [closeMyAccountMenu]);

  const showTopCategoriesPopover = useCallback(() => {
    if (topCategoriesTimeout.current !== null) clearTimeout(topCategoriesTimeout.current);
    topCategoriesTimeout.current = setTimeout(() => {
      setShowTopCategories(true);
    }, 250);
  }, []);
  const closeTopCategoriesPopover = useCallback(() => {
    if (topCategoriesTimeout.current !== null) clearTimeout(topCategoriesTimeout.current);
    topCategoriesTimeout.current = setTimeout(() => {
      setShowTopCategories(false);
    }, 150);
  }, []);

  const handleHamburgerMenuClick = useCallback(() => {
    if (showHamburgerMenu === true) {
      setShowHamburgerMenu(false);
    } else {
      setShowHamburgerMenu(true);
      closeMyAccountMenu();
    }
  }, [closeMyAccountMenu, showHamburgerMenu]);

  const refreshSearchFormInput = useCallback(() => {
    if (searchInputRef?.current) {
      if (query.has("q")) {
        setSearchText(query.get("q") as string);
      } else {
        setSearchText("");
      }
    }
  }, [query]);

  // When the location changes, we need to force close all the menus in the header.  This is especially important in mobile but makes sense for the rest as well.
  useEffect(() => {
    closeMyAccountMenu();
    setForceHideAutoComplete(true);
    closeTopCategoriesPopover();
    setShowHamburgerMenu(false);
    refreshSearchFormInput();
  }, [closeMyAccountMenu, closeTopCategoriesPopover, refreshSearchFormInput, pathname, searchParams]);

  const categoryListContainerStyles = `category-list-container ${showTopCategories ? "is-hovered" : ""}`;

  return (
    <header
      id={style.header}
      data-display-account-menu={showMobileMyAccount}
      data-display-hamburger-menu={showHamburgerMenu}
      data-display-homepage-search-enabled={headerSearchEnabled}
    >
      <a id="header-logo" className={`vertical-net32`} href="/">
        <h1>Net32</h1>
      </a>
      <div
        id="categories"
        ref={topCategoriesRef}
        onMouseOver={showTopCategoriesPopover}
        onMouseOut={closeTopCategoriesPopover}
        className={showTopCategories ? "boost-zindex is-hovered" : ""}
      >
        <SubCategoryMainMenuContainer show={showTopCategories} target={topCategoriesRef} />

        <CSSTransition in={showTopCategories} timeout={50} unmountOnExit>
          <div className="header-actions-black-background disable-click-events"></div>
        </CSSTransition>
      </div>
      {headerSearchEnabled &&
        (instantSearchProvided ? (
          <AlgoliaSearchField topCategories={topCategories} />
        ) : (
          <AlgoliaSearchBarClient topCategories={topCategories} />
        ))}

      <div id="my-account">
        {user?.IsGuestUser === false && <DeliverToShippingAddress />}

        <div className={`my-account-salutations ${!user || user.IsGuestUser ? "" : "logged-in-salutations"}`}>
          {user?.IsGuestUser === false ? (
            <span className="sm">
              <UserSalutations prefix={user.Prefix} firstName={user.FirstName} lastName={user.LastName} />
            </span>
          ) : (
            <React.Fragment>
              <a href={loginURL}>Sign-in</a>
              <a href={createAccountURL}>Register</a>
            </React.Fragment>
          )}
        </div>

        <div
          id="my-account-menu-container"
          ref={myAccountRef}
          onMouseOver={showMyAccountMenu}
          onMouseOut={closeMyAccountMenu}
        >
          <Link prefetch={false} href="/account" className="lg">
            My Account
          </Link>
          <Overlay
            show={showMyAccount}
            target={myAccountRef.current as any}
            placement="bottom"
            container={myAccountRef.current as any}
            transition={true}
          >
            <Popover id="my-account-menu-popover" className="">
              <Popover.Body>
                <MyAccountMenu
                  loginURL={loginURL}
                  createAccountURL={createAccountURL}
                  showMyAccountPreview={showMyAccountPreview}
                />
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      </div>
      <div id="my-account-mobile">
        {user?.IsGuestUser === false && <DeliverToShippingAddress />}
        {user?.IsGuestUser === false ? (
          <span id="my-account-mobile-menu-link" onClick={showMyAccountMenu}>
            <span className="sm">
              <UserSalutations prefix={user.Prefix} firstName={user.FirstName} lastName={user.LastName} />
            </span>
          </span>
        ) : (
          <div className="my-account-salutations">
            <a href={loginURL}>Sign-in</a>
            <a href={createAccountURL}>Register</a>
          </div>
        )}
      </div>
      <div id="my-account-mobile-open">
        <div id="show-mobile-search-bar" onClick={showSearchBarOnMobile}></div>
        <div id="close-my-account-mobile-menu" onClick={closeMyAccountMenu}></div>
      </div>
      <div id="my-account-mobile-menu">
        <MyAccountMenu
          loginURL={loginURL}
          createAccountURL={createAccountURL}
          showMyAccountPreview={showMyAccountPreview}
        />
      </div>

      <ShoppingCartSummary />

      <div id="header-secondary-row">
        <div>
          {shoudShowSwiperSideNavigationArrows() && (
            <div
              className={`swiper-arrow swiper-arrow-style swiper-arrow-prev swiper-arrow-primary`}
              ref={navigationPrevRef}
            ></div>
          )}
          <Swiper
            modules={[FreeMode, Mousewheel, Navigation]}
            spaceBetween={5}
            slidesPerView={"auto"}
            loop={false}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            watchOverflow={true}
            freeMode={{
              enabled: true,
              sticky: true,
            }}
            direction="horizontal"
            mousewheel={false}
          >
            <SwiperSlide>
              <Link prefetch={false} href="/weekly-specials" className="weekly-specials-link">
                Weekly Specials
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/top-sellers">
                Top Sellers
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/mfr-promotions">
                Promotions
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/composites-including-resins-hybrids-cosmetic-l-531-2">
                Composites including resins &amp; hybrids
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/local-anesthetic-l-536-847">
                Local anesthetic
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/cement-liners-adhesives-l-533-829">
                Cement
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/sterilization-pouches-infection-control-clinical-l-523-728">
                Sterilization pouches
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/gloves-infection-control-personal-l-509-569">
                Gloves
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/bonding-agents-cosmetic-l-531-818">
                Bonding agents
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/vinyl-polysiloxane-impression-materials-l-524-737">
                Vinyl polysiloxane
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/temporary-crown-bridge-materials-acrylics-reline-tray-l-538-855">
                Temporary crown &amp; bridge materials
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/wipes-towelettes-disinfecting-infection-control-clinical-l-523-719">
                Wipes &amp; towelettes, disinfecting
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/temporary-cement-liners-adhesives-l-533-828">
                Temporary cement
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/mixing-material-tips-impression-l-524-747">
                Mixing material tips
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/milling-blocks-cad-cam-l-220810-220811">
                Milling blocks
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/bone-grafting-material-soft-tissue-surgical-l-519-542-10">
                Bone grafting material
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/fluorides-preventives-l-515-618">
                Fluorides
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/angle-cup-prophy-preventives-l-515-614-56">
                Angle with cup
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/carbide-burs-diamonds-l-534-836">
                Carbide burs
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link prefetch={false} href="/ec/masks-infection-control-personal-l-509-567">
                Masks
              </Link>
            </SwiperSlide>
          </Swiper>
          {shoudShowSwiperSideNavigationArrows() && (
            <div
              className={`swiper-arrow swiper-arrow-style swiper-arrow-next swiper-arrow-primary`}
              ref={navigationNextRef}
            ></div>
          )}
        </div>
      </div>

      {user?.IsGuestUser === true && (
        <div id="get-started" title="It's free. No membership." className="mobile-grid-menu-item">
          <a href={createAccountURL}>Get started in 30 seconds</a>
        </div>
      )}

      <div id="hamburger-menu" onClick={handleHamburgerMenuClick}></div>

      <div id="mobile-category-list">
        <SubCategoryMainMenuContainerMobile />

        <div className="mobile-my-account">
          <Button href="/account">My Account</Button>
        </div>
      </div>
    </header>
  );
}
