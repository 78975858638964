import { useState } from "react";
import style from "./deliverToShippingAddress.oldStyle.module.scss";

import { useGetPrimaryShippingAddressQuery } from "@/store/userApi";
import ChooseShippingAddressModal from "./chooseShippingAddressModal";

export default function DeliverToShippingAddress() {
  const { data: primaryShippingAddress } = useGetPrimaryShippingAddressQuery();
  const [showChooseShippingDeliveryAddressModal, setShowChooseShippingDeliveryAddressModal] = useState(false);

  // const refreshPrimaryShippingAddressAndCloseModal = (refresh: boolean) => {
  //   setShowChooseShippingDeliveryAddressModal(false);

  //   if (refresh === true) {
  //     refreshPrimaryAddressCallBack();
  //   }
  // };

  if (primaryShippingAddress && primaryShippingAddress.Id !== null) {
    return (
      <div id={`${style["deliver-to"]}`}>
        <img
          className="deliver-to-pin"
          src="/media/dental/common/icons/map-pin.png"
          onClick={() => setShowChooseShippingDeliveryAddressModal(true)}
          alt="Deliver To"
        ></img>
        <div className="deliver-to-info" onClick={() => setShowChooseShippingDeliveryAddressModal(true)}>
          <span className="sm d-block">Deliver to</span>
          <span className="deliver-content">
            {primaryShippingAddress.City} {primaryShippingAddress.PostalCD}
          </span>
        </div>

        {showChooseShippingDeliveryAddressModal && (
          <ChooseShippingAddressModal
            showModal={showChooseShippingDeliveryAddressModal}
            // chooseShippingAddressModalCallBack={refresPrimaryShippingAddressAndCloseModal}
            setShowModal={(s: boolean) => {
              setShowChooseShippingDeliveryAddressModal(s);
            }}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
}
