import { useCallback } from "react";

import { resetApiState } from "@/store/baseApi";
import { useAppDispatch } from "@/store/hooks";
import { useGetUserQuery, useLogoutMutation } from "@/store/userApi";
import { removeCityAndZipcodeCookies } from "@/utils/locationDetails";

export default function MyAccountMenu({ loginURL, createAccountURL, showMyAccountPreview }: MyAccountMenuProps) {
  const dispatch = useAppDispatch();
  const { data: user } = useGetUserQuery();
  const [logout] = useLogoutMutation();
  const logoutCallback = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      try {
        const result = await logout().unwrap();
        if (result.CallHeader.StatusCode === "SC_OK") {
          removeCityAndZipcodeCookies();
          dispatch(resetApiState());
          window.location.href = "/";
        }
      } catch (error) {
        console.error("Logout error", error);
        alert("There was an error logging you out.  Please try again shortly.");
      }
    },
    [dispatch, logout],
  );
  return (
    <>
      {user?.IsGuestUser === true && (
        <>
          <a href={loginURL} className="btn btn-secondary">
            Sign-in
          </a>
          <a href={createAccountURL} className="btn btn-outline-secondary">
            Create Account
          </a>
        </>
      )}
      {showMyAccountPreview === false && (
        <>
          <a href="/dashboard" className="btn-link">
            Dashboard
          </a>
          <a href="/reorder" className="btn-link">
            Reorder
          </a>
          <a href="/account/orders/auto-order" className="btn-link">
            Auto Orders
          </a>
          <a href="/shopping-list" className="btn-link">
            Shopping List
          </a>
          <a href="/account" className="btn-link">
            My Account
          </a>
          <a href="/account/orders" className="btn-link">
            Orders
          </a>
          <a href="/review/products" className="btn-link">
            Review Purchased Products
          </a>
          <a href="/review/vendor" className="btn-link">
            Review Vendor
          </a>
          {user?.IsGuestUser !== true && (
            <a href="#" className="btn-link" onClick={logoutCallback}>
              Logout
            </a>
          )}
        </>
      )}
    </>
  );
}

type MyAccountMenuProps = {
  loginURL: string;
  createAccountURL: string;
  showMyAccountPreview: boolean;
};
