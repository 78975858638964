"use client";

import { FunctionComponent } from "react";
import { PatternFormat } from "react-number-format";

const VerticalPhoneNumber: FunctionComponent<VerticalPhoneNumberProps> = ({ format, type, className }) => {
  const chosenNumber = phoneNumbers[type];
  return (
    // <a> allows for click to call on mobile with the href set with tel:
    <a className={className} href={`tel:+1${chosenNumber}`}>
      <PatternFormat displayType="text" value={chosenNumber} format={format} />
    </a>
  );
};
export default VerticalPhoneNumber;

const phoneNumbers = {
  oneEightHundred: process.env.NEXT_PUBLIC_CUSTOMER_LOYALTY_PHONE_NUMBER_1_800,
  local: process.env.NEXT_PUBLIC_CUSTOMER_LOYALTY_PHONE_NUMBER_LOCAL,
};

export interface VerticalPhoneNumberProps {
  format: "+1 ##########" | "1-###-###-####" | "(###) ###-####" | "###-###-####";
  type: "oneEightHundred" | "local";
  className?: string;
}
