"use client";

import delay from "delay";
import { useCallback, useEffect, useState } from "react";

export default function GoogleReviewBadge({}: GoogleReviewBadgeProps) {
  const [includeScript, setIncludeScript] = useState(false);

  const renderBadge = useCallback(async () => {
    await delay(500);
    setIncludeScript(true);
  }, []);

  useEffect(() => {
    // This keeps the badge from rendering until it hits the client to avoid hydration errors
    if (includeScript === false) {
      renderBadge();
    }
  }, [includeScript, renderBadge]);

  return (
    <>
      <div
        className="google-site-reviews-badge"
        dangerouslySetInnerHTML={{
          __html: '<g:ratingbadge merchant_id="8223710"></g:ratingbadge>',
        }}
        suppressHydrationWarning={true}
      />
      {includeScript && <script src="https://apis.google.com/js/platform.js?onload=renderBadge" async defer></script>}
    </>
  );
}

export type GoogleReviewBadgeProps = {};
