import ProductImage from "@/app/ec/product/productImage";
import ShoppingCartCounter from "@/app/layouts/standard/header/shoppingCartCounter";
import { useGetShoppingCartQuery } from "@/store/shoppingCartApi";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Overlay, Popover } from "react-bootstrap";

const ShoppingCartSummary: FunctionComponent<ShoppingCartSummaryProps> = () => {
  const { data: shoppingCart } = useGetShoppingCartQuery();

  const pathname = usePathname();
  const [showShoppingCartSummary, setShowShoppingCartSummary] = useState(false);
  const shoppingCartSummaryRef = useRef(null);
  const showShoppingCartTimeout = useRef<number | null>(null);

  const shoppingCartProductsToShow = useMemo<ShoppingCartVendorOrderProduct[]>(() => {
    const products = !shoppingCart
      ? []
      : shoppingCart.vendorOrders
          .flatMap((vo) => {
            return vo.products;
          })
          .filter((p) => !p.noLongerForSale);
    products.sort((a, b) => {
      if (a.cartLineNumber < b.cartLineNumber) return 1;
      else if (a.cartLineNumber > b.cartLineNumber) return -1;
      return 0;
    });
    return products.slice(0, 5);
  }, [shoppingCart]);

  const showShoppingCartSummaryMenu = useCallback(() => {
    if (showShoppingCartTimeout.current !== null) {
      clearTimeout(showShoppingCartTimeout.current);
    }
    showShoppingCartTimeout.current = window.setTimeout(() => {
      setShowShoppingCartSummary(true);
    }, 50);
  }, []);
  const closeShoppingCartSummaryMenu = useCallback(() => {
    if (showShoppingCartTimeout.current !== null) {
      clearTimeout(showShoppingCartTimeout.current);
    }
    showShoppingCartTimeout.current = window.setTimeout(() => {
      setShowShoppingCartSummary(false);
    }, 150);
  }, []);

  useEffect(() => {
    closeShoppingCartSummaryMenu();
  }, [closeShoppingCartSummaryMenu, pathname]);

  // TODO - Re-enable functionality
  // useEffect(() => {
  //     const sideShoppingCatExperimentInterval = setInterval(() => {
  //         if (window.n32.isSideShoppingCartExperimentActive && window.n32.isSideShoppingCartExperimentActive === true && isSideShoppingCartExperimentActive === false) {
  //             setIsSideShoppingCartExperimentActive(true);
  //             clearInterval(sideShoppingCatExperimentInterval);
  //         }
  //     }, 200);

  //     return () => {
  //         clearInterval(sideShoppingCatExperimentInterval);
  //     }
  // }, [isPdpPage]);

  // TODO - Re-enable functionality
  // const showSideShoppingCartWrapper = () => {
  // 	if(isSideShoppingCartExperimentActive) {
  // 		dispatch(showSideShoppingCart());
  // 	}
  // }

  return (
    <>
      <div
        id="shopping-cart-summary"
        ref={shoppingCartSummaryRef}
        // TODO Re-enable with side shopping cart
        // onMouseOver={(isPdpPage && isSideShoppingCartExperimentActive )? showSideShoppingCartWrapper : showShoppingCartSummaryMenu}
        onMouseOver={showShoppingCartSummaryMenu}
        onMouseOut={closeShoppingCartSummaryMenu}
      >
        {typeof shoppingCart !== "undefined" && shoppingCart.totalQuantity > 0 && (
          <>
            <ShoppingCartCounter />
            <Overlay
              show={showShoppingCartSummary}
              target={shoppingCartSummaryRef.current as any}
              placement="bottom"
              container={shoppingCartSummaryRef.current as any}
              transition={true}
            >
              <Popover id="shopping-cart-summary-popover" className="">
                <Popover.Body>
                  <div id="shopping-cart-summary-list">
                    {shoppingCartProductsToShow.map((shoppingCartProduct) => {
                      return (
                        <div
                          key={
                            "shopping-cart-summary-" +
                            shoppingCartProduct.mpId +
                            "-" +
                            shoppingCartProduct.vendorProductId +
                            "-" +
                            shoppingCartProduct.quantity
                          }
                          className="shopping-cart-summary-product"
                        >
                          <div className="prod-image">
                            <ProductImage
                              src={shoppingCartProduct.smallImagePath}
                              alt={shoppingCartProduct.productTitle}
                              width={40}
                              height={40}
                            />
                          </div>
                          <div className="prod-desc">
                            <Link href={shoppingCartProduct.productLink}>{shoppingCartProduct.productTitle}</Link>
                            <span>Quantity: {shoppingCartProduct.quantity}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Button variant="secondary" href="/shopping-cart">
                    View Cart ({shoppingCart.totalQuantity})
                  </Button>
                </Popover.Body>
              </Popover>
            </Overlay>
          </>
        )}
        <a className="shopping-cart-summary-link" href="/shopping-cart">
          Shopping Cart
        </a>
      </div>
      <div id="shopping-cart-summary-mobile">
        <ShoppingCartCounter />
        <a className="shopping-cart-summary-link" href="/shopping-cart">
          Shopping Cart
        </a>
      </div>
    </>
  );
};

export type ShoppingCartSummaryProps = {};

export default ShoppingCartSummary;
