import Link from "next/link";
import { useEffect, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

import input from "./data/subCategory-options.json";

import style from "./subCategoryMenu.module.scss";

export default function SubCategoryMainMenuContainer({
  show,
  target,
}: {
  show: boolean;
  target: React.MutableRefObject<null>;
}) {
  const [data, setData] = useState<SubCategoryMainMenuDataItem[]>();
  useEffect(() => {
    const loadedData = JSON.stringify(input);
    const json = JSON.parse(loadedData);
    setData(json);
  }, []);

  return (
    <>
      <button className={style["menu-button"]}>Shop by category </button>
      <Overlay show={show} target={target} placement="bottom-end">
        <Popover>
          <Popover.Body>
            <div className={style["menu-wrapper"]}>
              <div className={style["menu-content"]}>
                {data?.map((category, index) => {
                  const categoryName = category.name;
                  return (
                    <div key={index} className={style["main-link"]}>
                      <div className={style["main-link-title"]}>{categoryName}</div>
                      <div className={style["submenu"]}>
                        <h3>{categoryName}</h3>
                        <div className={style["submenu-content"]}>
                          {category?.links?.map((subCategory, index) => {
                            return (
                              <Link key={index} href={subCategory?.href}>
                                {subCategory?.name}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export interface SubCategoryMainMenuDataItem {
  name: string;
  links: Array<SubCategorySubMenuItem>;
}

export interface SubCategorySubMenuItem {
  name: string;
  href: string;
}
