"use client";

import { useEffect, useState } from "react";

export default function BetterBusinessBureauBadge({}: BetterBusinessBureauBadgeProps) {
  const [includeScript, setIncludeScript] = useState(false);
  useEffect(() => {
    // This keeps the badge from rendering until it hits the client to avoid hydration errors
    setIncludeScript(true);
  }, []);
  return (
    <>
      <div className="bbb-badge">
        <a
          id="bbblink"
          className="rbhzbum"
          href="https://www.bbb.org/us/nc/cary/profile/dental-supplies/net32-inc-0593-90055367#bbbseal"
          title="Net32, Inc., Dental Supplies, Cary, NC"
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            id="bbblinkimg"
            src="https://seal-easternnc.bbb.org/logo/rbhzbum/net32-90055367.png"
            width="300"
            height="57"
            alt="Net32, Inc., Dental Supplies, Cary, NC"
          />
        </a>
      </div>
      {includeScript && (
        <>
          {/* <script src="https://seal-easternnc.bbb.org/logo/net32-90055367.js" type="text/javascript" async /> */}
          {/* 
            The following may break some day, the above script is supposed to load this but it tries 
            to do it with an onload event which we can't support.  This allows the image to change
            on hover.  In a way this is better than the script, the script does some other annoying
            things, but still.
            -Michael
           */}
          <link rel="stylesheet" type="text/css" href="https://seal-blue.bbb.org/legacy.min.css" />
        </>
      )}
    </>
  );
}

export type BetterBusinessBureauBadgeProps = {};
