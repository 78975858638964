import {
  useGetAllShippingAddressesQuery,
  useGetUserQuery,
  useSetShippingAddressAsPrimaryMutation,
} from "@/store/userApi";
import React, { FunctionComponent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import style from "./chooseShippingAddressModal.module.scss";

type Props = {
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  // showManualZipCode?: boolean;
};

const ChooseShippingAddressModal: FunctionComponent<Props> = ({ showModal, setShowModal }) => {
  const { data: shippingAddresses } = useGetAllShippingAddressesQuery();
  const { data: user, isLoading: userIsLoading } = useGetUserQuery();
  const [setShippingAddressAsPrimary] = useSetShippingAddressAsPrimaryMutation();
  const [chosenShippingAddress, setChosenShippingAddress] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (userIsLoading === false && user?.IsGuestUser !== true && user?.isAuthorized == true) {
      setIsLoading(true);
      if (chosenShippingAddress !== -1) {
        await setShippingAddressAsPrimary(chosenShippingAddress);
      }
      setShowModal(false);
      setIsLoading(false);
    } else {
      console.warn("Currently logged out!  Redirecting to login");
      window.location.href = "/login?origin=" + encodeURIComponent(window.location.pathname);
    }
  }

  return (
    <Modal
      id={style["choose-shipping-address-modal"]}
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <section className={style["choose-delivery-address-header"]}>
          <div className={style["choose-delivery-address-title"]}>Choose your delivery address</div>
          <div className={style["choose-delivery-address-subtitle"]}>
            Select your delivery location for more accurate shipping times and price estimates when shopping on Net32.
          </div>
        </section>

        <Form className={style["shipping-addresses-form"]} onSubmit={onSubmit}>
          {user ? (
            <>
              <Form.Group>
                {shippingAddresses?.map((shippingAddress, index) => {
                  return (
                    <Form.Check
                      key={shippingAddress.Id}
                      type="radio"
                      id={`shipping-address=${index}`}
                      name="shipping-address"
                      className="pb-2"
                      defaultChecked={shippingAddress.IsPrimary}
                      onChange={() => {
                        setChosenShippingAddress(shippingAddress.Id);
                      }}
                      label={
                        <div>
                          <div className={style["shipping-address-name"]}>
                            {shippingAddress.Name}{" "}
                            {shippingAddress.IsPrimary === true ? (
                              <span className={style["primary-shipping-address"]}>Primary Address</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className={style["shipping-adresss-text"]}>
                            {shippingAddress.Streets.map((street, index2) => (
                              <span className={style["shipping-adresss-street"]} key={street + index2}>
                                {street}
                              </span>
                            ))}
                            , {shippingAddress.City}, {shippingAddress.RegionCD} {shippingAddress.PostalCD}
                          </div>
                        </div>
                      }
                    ></Form.Check>
                  );
                })}
              </Form.Group>

              <Form.Group>
                <a className="highlighted-link" href="/account/shipping">
                  Manage address book
                </a>
              </Form.Group>
            </>
          ) : (
            <div className="form-sign-in">
              <Button
                variant="secondary"
                size="lg"
                href={"/login?origin=" + encodeURIComponent(window.location.pathname)}
                className={`${isLoading ? "loading-complex" : ""}`}
              >
                Sign in to see your addresses
              </Button>
            </div>
          )}

          {/* TODO - Re-enable zipcode */}
          {/* {showManualZipCode && (
            <div className="form-zipcode">
              <div className="form-zipcode-title">
                <div className="form-zipcode-title-line" />

                <span>or enter a US zip code</span>

                <div className="form-zipcode-title-line" />
              </div>

              {isZipCodeSubmitted && isValidZipCode ? (
                <div className="form-zipcode-selected">
                  <span className="form-zipcode-info">
                    Deliver to <b>{zipCode}</b>
                  </span>
                  <Button variant="link" onClick={() => setIsZipCodeSubmitted(false)}>
                    Change
                  </Button>
                </div>
              ) : (
                <div className="form-zipcode-input">
                  <label className="form-zipcode-input-label" htmlFor="form-zipcode-input-text">
                    Zip code
                  </label>
                  <div className="form-zipcode-input-text-button">
                    <input
                      id="choose-shipping-address-zipcode-input"
                      name="choose-shipping-address-zipcode-input"
                      value={zipCode}
                      type="number"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setZipCode(event.target.value)}
                      height="48"
                    />
                    <Button
                      className="form-zipcode-input-button"
                      variant="outline-secondary"
                      size="lg"
                      disabled={isZipCodeEmpty}
                      onClick={() => getZipCodeDetails()}
                    >
                      Apply
                    </Button>
                  </div>
                  {isZipCodeSubmitted && !isValidZipCode && (
                    <div className="form-zipcode-input-error mt-1">
                      Zip code not found. Please try another zip code.
                    </div>
                  )}
                </div>
              )}
            </div>
          )} */}

          <div className={style["form-action"]}>
            <Button type="submit" variant="secondary" size="lg">
              Done
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChooseShippingAddressModal;
