"use client";

import apiKeys from "@/app/components/apiKeys";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./takeDownRequests.module.scss";

export default function TakeDownRequests({}: TakeDownRequestsProps) {
  const [showTakeDownModal, setShowTakeDownModal] = useState(false);
  const [googleRecaptchaCompleted, setGoogleRecaptchaCompleted] = useState(false);

  return (
    <>
      <li>
        <a
          href="#"
          id="take-down-request-link"
          rel="nofollow"
          onClick={(event) => {
            event.preventDefault();
            setShowTakeDownModal(true);
          }}
        >
          Take Down Request
        </a>
      </li>
      <Modal show={showTakeDownModal} centered onHide={() => setShowTakeDownModal(false)} backdrop={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form id={styles["takedown-modal-contents"]} action="/takedown-request" method="POST">
            <h5>Take Down Policy</h5>
            <p>
              Net32 respects the intellectual property rights of others. If you believe your rights have been violated
              by any product advertised on our platform, you may provide us with a notice of infringement by completing
              our <strong>Take Down Policy</strong> form. If you proceed to our form, please be sure to complete all
              required fields clearly and accurately, including your full contact details and information on the
              infringement. An incomplete or ambiguous notice may prevent Net32 from thoroughly investigating and
              quickly resolving the issue you raise.
            </p>
            <p className="lg medium">Do you wish to continue to Net32&apos;s&apos; Take Down Request form?</p>
            {apiKeys.googleRecaptcha && (
              <ReCAPTCHA
                sitekey={apiKeys.googleRecaptcha}
                onChange={() => setGoogleRecaptchaCompleted(true)}
                onExpired={() => setGoogleRecaptchaCompleted(false)}
              />
            )}
            {!apiKeys.googleRecaptcha && <p>Could not load Google Recaptcha!</p>}
            <div className={styles["takedown-button-group"]}>
              <Button variant="outline-primary" size="lg">
                Cancel
              </Button>
              <Button variant="primary" size="lg" disabled={!googleRecaptchaCompleted} type="submit">
                Continue
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export type TakeDownRequestsProps = {};
