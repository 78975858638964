import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

/**
 * @deprecated This wraps around useSearchParams, you should use that instead
 */
export function useQuery() {
  // const router = useRouter();
  const searchParams = useSearchParams();

  return useMemo(() => {
    const queryParams = new URLSearchParams();
    searchParams?.forEach((key, value) => {
      queryParams.append(key, `${value}`);
    });
    return queryParams;
  }, [searchParams]);
}
