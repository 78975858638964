"use client";

import EmailInput from "@/app/components/emailInput";
import useAnalyticsManager from "@/app/hooks/useAnalyticsManager";
import useEmailSubscriber from "@/app/hooks/useEmailSubscriber";
import SocialMediaLinks from "@/app/layouts/standard/footer/socialMediaLinks";
import { useGetUserQuery } from "@/store/userApi";
import BasicException from "@/utils/basicException";
import * as EmailValidator from "email-validator";
import { useState } from "react";

export default function EmailSubscribe() {
  const [email, setEmail] = useState("");
  const { data: user } = useGetUserQuery();
  const [userSubscribedToEmail, setUserSubscribedToEmail] = useState<null | boolean>(null);
  const [userSubscribeFailureMessage, setUserSubscribeFailureMessage] = useState("");
  const subscribeToEmailLists = useEmailSubscriber();
  const { dispatchEmailSubscriptionEvent } = useAnalyticsManager();

  async function subscribe(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (EmailValidator.validate(email) === false) {
        setUserSubscribedToEmail(false);
        setUserSubscribeFailureMessage("You have entered an invalid email.");
      } else {
        setUserSubscribedToEmail(null);
        setUserSubscribeFailureMessage("");
      }

      const response = await subscribeToEmailLists({
        emailAddress: email,
        subscriptionType: "Weekly Email;Replenish Email;Shopping Cart Reminder",
        source: "popup",
        isLoggedInUser: user?.IsGuestUser === false ? 1 : 0,
      });
      if (response instanceof BasicException) {
        throw "Basic Exception thrown from fetch: " + response.httpErrorCode;
      } else {
        if (response.CallHeader.StatusCode == "SC_OK") {
          setUserSubscribedToEmail(true);
          dispatchEmailSubscriptionEvent(email);
        } else if (response.CallHeader.StatusCode == "SC_WARNING_ALREADY_EXIST") {
          setUserSubscribedToEmail(false);
          setUserSubscribeFailureMessage("You are already subscribed for Weekly Specials.");
        } else {
          throw "Unhandled status code: " + response.CallHeader.StatusCode;
        }
      }
    } catch (error) {
      console.error("Subscription error: ", error);
      setUserSubscribedToEmail(false);
      setUserSubscribeFailureMessage("We can't subscribe you at this time, please contact customer service.");
    }
  }
  return (
    <section id="subscribe-to-email">
      <strong className="h6">Never miss another deal</strong>
      <p id="email-capture-text" className="light">
        Join tens of thousands of dental professionals receiving the best deals every week.
      </p>
      <form className="form-group" id="email-subscribe-form-footer" onSubmit={subscribe}>
        <EmailInput
          size="lg"
          placeholder="Enter email"
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
        />
        <div id="email-subscribe-form-footer-button-group">
          <div className="subscribe-button-container">
            <input
              id="submit-email-subscription-footer"
              type="submit"
              value="Submit"
              className="btn btn-secondary btn-pillbox"
            />
          </div>
          <div className="subscribe-messages sm text-danger">{userSubscribeFailureMessage}</div>
        </div>
      </form>
      <div id="subscribe-to-email-success" data-show-success={userSubscribedToEmail === true ? "true" : "false"}>
        <img src="/media/shared/common/icons/confirm.png" alt="Confirm" />
        <span>Thanks for subscribing, Follow us on</span>
        <SocialMediaLinks />
      </div>
    </section>
  );
}
