"use client";

export default function SocialMediaLinks() {
  return (
    <div className="social-media-footer-icons">
      <a className="facebook-link" href="https://www.facebook.com/pages/Net32/497923145296">
        Facebook
      </a>
      <a className="twitter-link" href="https://twitter.com/Net32Inc">
        Twitter
      </a>
      <a className="linkedin-link" href="https://www.linkedin.com/company/net32-inc">
        LinkedIn
      </a>
      <a className="youtube-link" href="https://www.youtube.com/user/Net32Inc">
        Youtube
      </a>
      <a className="pinterest-link" href="https://www.pinterest.com/net32inc">
        Pinterest
      </a>
      <a className="reddit-link" href="https://www.reddit.com/user/Net32DentalSupplies">
        Reddit
      </a>
    </div>
  );
}
