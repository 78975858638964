import { useGetShoppingCartQuery } from "@/store/shoppingCartApi";

export default function ShoppingCartCounter() {
  const { data: shoppingCart } = useGetShoppingCartQuery();
  const defaultClassName = "shopping-cart-summary-counter";
  if (!shoppingCart) {
    return <div className={`${defaultClassName} d-none`}></div>;
  }
  return (
    <div className={`${defaultClassName}`} data-greater-than-99={shoppingCart.totalQuantity > 99}>
      {shoppingCart.totalQuantity}
    </div>
  );
}
