export default function UserSalutations({ prefix, firstName, lastName }: Props) {
  let userSalutationsString = "";
  if (prefix) {
    userSalutationsString = `${prefix} ${lastName}`;
  } else {
    userSalutationsString = firstName as string; // this will always exist if you get here
  }
  return <>Hello, {userSalutationsString}</>;
}

interface Props {
  prefix?: string;
  firstName: string;
  lastName: string;
}
